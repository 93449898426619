import { useContext, useState } from "react";
import { RecipesContext } from "../../App";
import Card from "../../components/Card/Card";
import CategoryBadge from "../../components/Badge/CategoryBadge";
import Apero from "../../assets/img/apero.png";
import Entry from "../../assets/img/entree.png";
import Meal from "../../assets/img/plat.png";
import Dessert from "../../assets/img/dessert.png";
import Drink from "../../assets/img/boisson.png";
import Sauce from "../../assets/img/sauce.png";
import Other from "../../assets/img/autre.png";
import Navbar from "../../components/Navbar/Navbar";

const AllRecipesPage: React.FC = () => {
  const [categoryFilter, setCategoryFilter] = useState<string>("");
  const [titleFilter, setTitleFilter] = useState<string>("");
  const data = useContext(RecipesContext);

  return (
    <div>
      <Navbar />
      <div className="min-h-screen overflow-x-hidden overflow-y-visible pb-20">
        <div className="mx-auto mt-10 w-full px-4 lg:w-1/2">
          <input
            className={
              "mx-auto mb-8 h-10 w-full rounded-xl border border-none !px-4 py-6 accent-orange shadow-sm outline-none ring-0 placeholder:opacity-70 active:border-0"
            }
            type="text"
            id="title-filter"
            onChange={(e) => setTitleFilter(e.target.value)}
            placeholder="Rechercher une recette"
          />
        </div>
        <div className="mx-auto mb-9 w-11/12 max-w-7xl overflow-auto pb-1">
          <div className="mx-auto flex w-fit">
            <CategoryBadge
              image={null}
              title={"Toutes les catégories"}
              onClick={() => setCategoryFilter("")}
              selected={categoryFilter === ""}
            />
            <CategoryBadge
              image={Apero}
              title={"Apéro"}
              onClick={() => setCategoryFilter("aperitif")}
              selected={categoryFilter === "aperitif"}
            />
            <CategoryBadge
              image={Entry}
              title={"Entrée"}
              onClick={() => setCategoryFilter("entry")}
              selected={categoryFilter === "entry"}
            />
            <CategoryBadge
              image={Meal}
              title={"Plat"}
              onClick={() => setCategoryFilter("meal")}
              selected={categoryFilter === "meal"}
            />
            <CategoryBadge
              image={Dessert}
              title={"Dessert"}
              onClick={() => setCategoryFilter("dessert")}
              selected={categoryFilter === "dessert"}
            />
            <CategoryBadge
              image={Drink}
              title={"Boisson"}
              onClick={() => setCategoryFilter("drink")}
              selected={categoryFilter === "drink"}
            />
            <CategoryBadge
              image={Sauce}
              title={"Sauce"}
              onClick={() => setCategoryFilter("sauce")}
              selected={categoryFilter === "sauce"}
            />
            <CategoryBadge
              image={Other}
              title={"Autre"}
              onClick={() => setCategoryFilter("other")}
              selected={categoryFilter === "other"}
            />
          </div>
        </div>
        <div
          className={
            "mx-auto grid w-11/12 max-w-7xl break-inside-avoid-column grid-cols-1 gap-4 overflow-y-visible sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          }
        >
          {data
            .filter((d: any) => categoryFilter === "" || d.categories.includes(categoryFilter))
            .filter(
              (d: any) =>
                titleFilter === "" ||
                d.title
                  .replaceAll(" ", "")
                  .toLowerCase()
                  .normalize("NFD")
                  .replaceAll(/[\u0300-\u036f]/g, "")
                  .includes(
                    titleFilter
                      .replaceAll(" ", "")
                      .toLowerCase()
                      .normalize("NFD")
                      .replaceAll(/[\u0300-\u036f]/g, ""),
                  ),
            )
            .map((d: any, index: number) => {
              return <Card data={d} key={index} />;
            })}
        </div>
        {data
          .filter((d: any) => categoryFilter === "" || d.categories.includes(categoryFilter))
          .filter(
            (d: any) =>
              titleFilter === "" ||
              d.title
                .replaceAll(" ", "")
                .toLowerCase()
                .normalize("NFD")
                .replaceAll(/[\u0300-\u036f]/g, "")
                .includes(
                  titleFilter
                    .replaceAll(" ", "")
                    .toLowerCase()
                    .normalize("NFD")
                    .replaceAll(/[\u0300-\u036f]/g, ""),
                ),
          ).length === 0 && <div className="text-orange-900 mt-8 text-center text-2xl font-bold">Aucune recette</div>}
      </div>
    </div>
  );
};

export default AllRecipesPage;
